import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/team/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/team/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/team/update',
        method: 'POST',
        data
    });
}

// 详情
export function DetailMenu(params) {
    return request({
        url: '/admin/team/detail',
        method: 'GET',
        params
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/team/delete',
        method: 'GET',
        params
    });
}

/**
 * 启用/禁用
 */
export function AdminEnabled(params) {
    return request({
        url: '/admin/team/changeIndex',
        method: 'GET',
        params
    });
}

/**
 * 设置服务结束时间
 */
 export function ChangeServiceEndTime(data) {
    return request({
        url: '/admin/team/changeServiceEndTime',
        method: 'POST',
        data
    });
}

/**
 * 设置微信小程序
 */
export function ChangeWechat(data) {
    return request({
        url: '/admin/team/changeWxApp',
        method: 'POST',
        data
    });
}

// 获取账号密码
export function selectPwd(params) {
    return request({
        url: '/admin/team/selectPwd',
        method: 'GET',
        params
    });
}

/**
 * 修改密码
 */
 export function ChangeIdPas(data) {
    return request({
        url: '/admin/team/changePwd',
        method: 'POST',
        data
    });
}